import SEO from '@components/SEO';
import IconArrow from '@icons/IconArrow';
import { Col, Form, Row } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import Input from '@components/Input';
import { InputType } from '@utils/enums/InputType';
import IconCall from '@icons/IconCall';
import IconEmail from '@icons/IconEmail';
import IconLocation from '@icons/IconLocation';
import { Link, navigate } from 'gatsby';
import constant from '@utils/constant';
import Footer from '@views/default-layout/footer/Footer';
import Breakpoint from '@components/Breakpoint/Breakpoint';
import { useSelector } from 'react-redux';
import { getProfileSelector } from '@utils/selectors';
import { userService } from '@services/userService';
import { message } from '@components/Message';

const formDefinitions = [
   {
      name: 'name',
      placeholder: 'Your name *',
      inputTitle: (
         <>
            Your name <span className="required">*</span>
         </>
      ),
      rules: [
         {
            required: true,
            message: 'Please input your Name.',
         },
      ],
   },
   {
      name: 'email',
      placeholder: 'Email *',
      inputTitle: (
         <>
            Email <span className="required">*</span>
         </>
      ),
      rules: [
         {
            required: true,
            message: 'Please confirm your Email.',
         },
         {
            type: 'email',
            message: 'Please use a valid email address.',
         },
      ],
   },
   {
      name: 'phone_number',
      placeholder: 'Phone Number',
      inputTitle: 'Phone Number',
   },
   {
      name: 'message',
      placeholder: 'Message *',
      type: InputType.TEXTAREA,
      inputTitle: (
         <>
            Message <span className="required">*</span>
         </>
      ),
      rules: [
         {
            required: true,
            message: 'Please confirm your Message.',
         },
      ],
   },
];

const Contact = memo(() => {
   const [submitting, setSubmitting] = useState(false);
   const { data: profileData, loading: profileLoading } = useSelector(getProfileSelector);
   const [form] = Form.useForm();

   useEffect(() => {
      if (!profileLoading && profileData?.username) {
         console.log(profileData);
         form.setFieldsValue({
            name: profileData.full_name || '',
            email: profileData.email || '',
            phone_number: profileData.phone_number || '',
         });
      }
   }, [profileLoading, profileData, form]);

   const onFinish = useCallback(async (values) => {
      await setSubmitting(true);
      console.log(values);

      const response = await userService.createContact({
         full_name: values.name,
         email: values.email,
         phone_number: values.phone_number,
         message: values.message,
      });
      if (response.isSuccess) {
         message.success('Your message has been sent. Thank you for contacting us!');
         setTimeout(() => {
            navigate(constant.ROUTE_HOME);
         }, 500);
      } else {
         message.error(response.message);
      }

      setSubmitting(false);
   }, []);

   const renderInfo = useCallback(() => {
      return (
         <div className="contact-page__info">
            <a
               href={`tel:${constant.CONTACT_PHONE_NO}`}
               className="contact-page__info--item"
               target="_blank"
               rel="noreferrer"
            >
               <IconCall />
               <span>{constant.CONTACT_PHONE_NO}</span>
            </a>
            <a
               href={`mailto:${constant.CONTACT_EMAIL}`}
               className="contact-page__info--item"
               target="_blank"
               rel="noreferrer"
            >
               <IconEmail />
               <span>{constant.CONTACT_EMAIL}</span>
            </a>
            <div className="contact-page__info--item">
               <IconLocation />
               <span>Ho Chi Minh City, Viet Nam</span>
            </div>
         </div>
      );
   }, []);

   return (
      <>
         <SEO title={'Contact'} />
         <div className="login-register contact-page">
            <div className="section-title">
               <span className="section-title-name animate__animated animate__fadeIn">
                  CONTACT
                  <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                     <IconArrow />
                  </span>
               </span>
            </div>
            <Row gutter={30} justify="space-between">
               <Col xl={14} lg={10} md={24} sm={24} xs={24}>
                  <div className="contact-page__message">
                     Fill out the form and our Team will get back to you within 24 hours.
                  </div>
                  <Breakpoint lg up>
                     {renderInfo()}
                  </Breakpoint>
               </Col>
               <Col xl={10} lg={14} md={24} sm={24} xs={24}>
                  <Form className="submit-form" form={form} onFinish={onFinish}>
                     {formDefinitions.map((item) => {
                        return (
                           <Form.Item
                              key={item.name}
                              name={item.name}
                              dependencies={item.dependencies}
                              rules={item.rules || []}
                           >
                              <Input
                                 type={item.type}
                                 name={item.name}
                                 inputTitle={item.inputTitle}
                                 placeholder={item.placeholder}
                              />
                           </Form.Item>
                        );
                     })}
                     <Form.Item>
                        <button
                           className="common-button"
                           type="primary"
                           disabled={submitting}
                        >
                           {submitting ? 'Sending...' : 'Send Message'}
                        </button>
                     </Form.Item>
                  </Form>
                  <Breakpoint md down>
                     {renderInfo()}
                  </Breakpoint>
               </Col>
            </Row>
         </div>
         <Footer />
      </>
   );
});

export default Contact;
